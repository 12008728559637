import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})

// export const authGuard: CanActivateFn = (route, state) => {
//   return true;
// };

export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
  ) {}

  canActivate() {
    let token = localStorage.getItem('ltokens')
    if (token) {
      return true
    }
    this.router.navigate(['admin/authentication/page-login']);
    return false;
  }
}