import { ExcelService } from './services/excel.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppComponent } from './app.component';
import { routing } from './app.routing';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { FullCalendarModule } from 'ng-fullcalendar';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
// import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { NgxGalleryModule } from 'ngx-gallery';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import * as $ from 'jquery';
import { GraphQLModule } from './graphql.module';
import { HttpClientModule } from '@angular/common/http';
import { MomentModule } from 'ngx-moment';
import { NgxPaginationModule } from 'ngx-pagination';
import { EditorModule } from '@tinymce/tinymce-angular';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { APP_BASE_HREF } from '@angular/common';
// for HttpClient import:
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';

// for Router import:
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
@NgModule({
    declarations: [
        AppComponent,

    ],
    imports: [
        BrowserModule,
        routing,
        NgbModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot(),
        FullCalendarModule,
        NgMultiSelectDropDownModule.forRoot(),
        // LeafletModule.forRoot(),
        NgxGalleryModule,
        // for HttpClient use:
        LoadingBarHttpClientModule,

    SweetAlert2Module.forRoot(),
    // for Router use:
        LoadingBarRouterModule,
        GraphQLModule,
        HttpClientModule,
        ReactiveFormsModule,
        MomentModule,
        NgxPaginationModule,
        EditorModule,
       
    ],
    providers: [{
        provide: LocationStrategy,
        useClass: HashLocationStrategy,
    }, { provide: APP_BASE_HREF, useValue: '/admin' },
        ExcelService,
    ],

    bootstrap: [AppComponent]
})
export class AppModule { }
